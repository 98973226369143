* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    scroll-behavior: smooth;
}

body {
    background-color: #1D263B;
}


/* Text watermark */
body::before {
  content: "Ongoing Upgrades. Thank You for Your Patience";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem; /* Adjust the size as needed */
  font-weight: bolder;
  color: rgba(214, 214, 214, 0.528); /* Adjust the color and opacity as needed */
  white-space: normal; /* Prevents text from wrapping */
  pointer-events: none; /* Make sure it doesn't interfere with any clickable elements */
  z-index: -1; /* Place it behind all other content */
}


h1, h2, h3 {
    font-family: 'Fira Mono', monospace;
}

h2 {
    margin: 0 auto;
    text-align: center;
    font-size: 1.75em;
    font-weight: 700;
}

h3 {
    margin: 0 auto;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700;
    letter-spacing: 0.1em;
}

a, span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.green {
    color: #73e134;
}

.bold {
    font-weight: 700;
}

.pink {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: #d63833;
}

ul {
    list-style-type: none;
}

.social-link a i {
    transition: all 0.2s ease;
    text-decoration: none;
}

.social-link a i:hover {
    color: #d63833;
}

.main-container {
    margin: 0 auto;
    padding: 0 25px;
    text-align: center;
    max-width: 1600px;
}


.section {
    margin: 0 auto;
    display: flexbox;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-top: 8vh;
}

.hyperlink {
    color: #d63833;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 3px;
    transition: all 0.2s ease;
}

.hyperlink:hover {
    color: #7EB77F;
}

.section-description {
    margin: 50px auto 0 auto;
    text-align: center;
    max-width: 1000px;
    line-height: 1.5em;
    letter-spacing: 0.025em;
}

.page-title {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-title img {
    height: 40px;
    width: auto;
    margin-right: 20px;
}

@media only screen and (min-width: 768px) {
    h2 {
        font-size: 2.5em;
    }
    h3 {
        font-size: 2em;
    }
}