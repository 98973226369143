.link {
  color: rgb(255, 255, 255);
  padding: 0.6rem; /* Adjust as necessary */
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
}

.link:hover {
  /* Change to the desired hover color */
  text-decoration: underline;
  text-underline-offset: 5px;
}

.nav-toggle {
  background-color: transparent;
  font-size: 1.8em;
  border: none;
  color:  rgb(255, 255, 255);
  cursor: pointer;
}

.nav-hide {
  transform: translateX(100%);
}

.nav-show {
  transform: translateX(0);
}

.nav-offcanvas {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.542);
  padding: 20px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
}

.nav-close {
  font-size: 40px;
  background: none;
  border: none;
  color: #d63833;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 992px) {
  .nav-links-large-screen {
    display: none;
  font-weight: 800;
  }

  .nav-toggle {
    display: block;
  }

  .nav-toggle {
    font-size: 30px;
    vertical-align: middle;
  }
}


@media (min-width: 993px) {
  .nav-offcanvas, .overlay, .nav-toggle {
    display: none;
  }

  .nav-links-large-screen {
    display: flex;
    font-size: 20px;
  }


  .nav-container {
    padding-bottom: 10px;
  }


}