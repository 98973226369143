.gallery-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto; /* Ensures the container takes full viewport height */
  background-color: transparent; /* Optional: Add a background color for better visualization */
}

.thumbnails {
  position: absolute;
  bottom: 16px; /* More space at the bottom for better visibility */
  display: flex;
  flex-direction: row;
  gap: 12px; /* Increase gap for better spacing */
}

.thumbnail {
  width: 12px; /* Bigger thumbnails */
  height: 12px;
  cursor: pointer;
  background: #aaa;
  border-radius: 100%;
}

.thumbnail.highlighted {
  background-color: #777;
}

.slides {
  margin: 0 16px;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100%; /* Increase width */
  max-width: 1080px;
  height: 100%; /* Increase height */
  max-height: 920px;
  padding: 0 0.25rem;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.slides > div {
  scroll-snap-align: start;
}

.slides img {
  width: 1080px; /* Match the width of the container */
  height: 920px; /* Match the height of the container */
  object-fit: scale-down;
}

.slides::-webkit-scrollbar {
  display: none;
}

@media (max-width: 820px) {
  .slides {
    max-width: 540px; /* Adjust max-width for smaller screens */
    max-height: 720px; /* Adjust max-height for smaller screens */
  }
}

@media (max-width: 580px) {
  .slides {
    max-width: 360px; /* Adjust max-width for smaller screens */
    max-height: 480px; /* Adjust max-height for smaller screens */
  }
}